import React, { useEffect, useState } from 'react'
import CommonButton from '../../component/common/CommonButton'
import { CommonModal } from '../../component/common/modal/CommonModal'
import Dropzone from 'react-dropzone'
import { addAplication, addBoard, editAplication, editBoard, getAplication, getBoard, uploadImage } from '../../../redux/Actions/AuthUser'
import { useDispatch } from 'react-redux'
import Loader from '../../component/Loader'
import { ErrorToast, SuccessToast } from '../../../lib/Toast'
import ErrorHandler from '../../../lib/ErrorHandler'
import { AllowedFiles } from '../../../lib/AlloweFiles'
import CommonTextField from '../../component/common/CommonTextField'
import CommonRadio from '../../component/common/CommonRadio'

const Application = () => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState<any>(false)
    const [application, setApplication] = React.useState<any>()
    const [applicationData, setApplicationData] = React.useState<any>('')
    const [isForceUpdate, setIsForceUpdate] = React.useState<any>()
    const [id, setId] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [errors, setError] = useState<any>([])
    const [isEdit, setIsEdit] = useState<any>(false)

    // Modal close
    const handleClose = () => {
        setModal(!modal)
        setIsEdit(false)
    }
    const isValid = () => {
        let errors: any = {}
        let formIsValid = true
        if (!application) {
            formIsValid = false
            errors['application'] = 'Please enter application version.'
        }
        setError(errors)
        return formIsValid
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setApplication(value)
    }

    const handleRadioChange = (event: any) => {
        setIsForceUpdate(event.target.value === "true" ? true : false);
    };

    const getApplicationData = async () => {
        setLoading(true)
        const body = ''
        const response: any = await dispatch(getAplication(body))
        setApplicationData(response?.payload?.data?.[0]?.appVersion)
        setIsForceUpdate(response?.payload?.data?.[0]?.isForceUpdate)
        setLoading(false)
    }

    const addApplicationData = async () => {
        if (isValid()) {
            setLoading(true)
            const body: any = {
                appVersion: application,
                isForceUpdate: isForceUpdate
            }
            if (isEdit) {
                body.id = id
            }
            const response: any = isEdit ? await dispatch(editAplication(body)) : await dispatch(addAplication(body))
            const error = ErrorHandler(response)
            setLoading(false)
            if (error) {
                setIsEdit(false)
                setModal(false)
                setApplication('')
                SuccessToast(response?.payload?.message)
                getApplicationData()
            }
        }
    }

    useEffect(() => {
        getApplicationData()
    }, [])

    useEffect(() => {
        setApplication(applicationData)
    }, [isEdit])

    return (
        <>
            <CommonModal
                handleClose={handleClose}
                modal={modal}
                setModal={setModal}
                title={'Application version'}
            >
                <div className='card mb-5' id='kt_profile_details_view'>
                    <div className='card-body p-4'>
                        <div className='row mb-6'>
                            <div className='col-lg-12'>
                                <CommonTextField
                                    label={'Application version'}
                                    name={'application'}
                                    value={application || ''}
                                    type={'text'}
                                    placeholder={'Application version'}
                                    onChange={handleChange}
                                />
                                <div className='text-danger'>{application ? '' : errors?.application}</div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div>
                                <label className="col-lg-12 col-form-label fw-bold fs-6">Is force update</label>
                                <div className="form-check" style={{ marginBottom: "10px" }}>
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="red"
                                        name="colorRadio"
                                        value="true"
                                        checked={isForceUpdate}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="red">
                                        True
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="blue"
                                        name="colorRadio"
                                        value="false"
                                        checked={!isForceUpdate}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="blue">
                                        False
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <CommonButton
                                text={`${isEdit ? 'Edit Application version' : 'Add Application version'}`}
                                onClick={addApplicationData}
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
            <div className='d-flex justify-content-end'>
                {!applicationData ? (
                    <CommonButton
                        text={'Add Application Version'}
                        onClick={() => {
                            setModal(!modal)
                        }}
                    />
                ) : (
                    <CommonButton
                        text={'Edit Application Version'}
                        onClick={() => {
                            setModal(!modal)
                            setIsEdit(true)
                        }}
                    />
                )}
            </div>
            {applicationData && (
                <div className='container bg-white shadow-sm rounded-3'>
                    <div className='d-flex align-items-center justify-content-between pt-5 pb-5'>
                        <h3 className='d-flex align-items-center'>
                            Application version
                        </h3>
                    </div>
                    <div className='row p-4'>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12 m-auto'>
                            <h1>{applicationData}</h1>
                        </div>
                    </div>
                </div>
            )}
            <Loader open={loading} />
        </>
    )
}

export default Application
