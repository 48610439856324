import jwt_decode from 'jwt-decode'
import Axios from 'axios'
// import { parseJwt } from "@utils/parseToken";
import {apiInstance} from './apiInstance'
import {BaseURL, versionControl} from './Api'
import localStoreUtil from '../_metronic/helpers/localstorage'

export const parseJwt = (token) => {
  try {
    return jwt_decode(token)
  } catch (e) {
    return null
  }
}

export function setToken(token) {
  Object.assign(apiInstance.defaults.headers, {
    Authorization: `Bearer ${token}`,
  })
}
export function removeToken() {
  delete apiInstance.defaults.headers.Authorization
}
export function getToken() {
  if (
    apiInstance.defaults.headers.Authorization &&
    apiInstance.defaults.headers.Authorization.toLowerCase().indexOf('bearer') != -1
  ) {
    return apiInstance.defaults.headers.Authorization.split(' ')[1]
  }
  return ''
}

export async function handleRequest(request) {
  // let login_token = await localStorage.getItem("access_token");
  let login_token = await localStoreUtil.get_data('access_token')
  // let all_token = await JSON.parse(localStorage.getItem("token"));
  let all_token = await localStoreUtil.get_data('token')

  if (login_token) {
    let decodedJwt = parseJwt(login_token)
    const body = {
      token: login_token,
      refreshToken: all_token?.refreshToken,
    }
    if (Math.floor(new Date().getTime() / 1000) >= decodedJwt?.exp - 5 * 1) {
      await fetch(BaseURL + 'api/' + versionControl + '/client/refreshtoken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'bearer ' + localStorage.getItem('access_token'),
        },
        body: JSON.stringify({
          token: login_token,
          refreshToken: all_token?.refreshToken,
        }),
      })
        .then((r) => r.json())
        .then(async (response) => {
          if (response?.code === 0) {
          } else if (response?.result?.token) {
            await setToken(response?.result?.token)
            await localStorage.setItem('access_token', response?.result?.token)
            await localStorage.setItem('token', JSON.stringify(response?.result))
            request.headers.Authorization = `Bearer ${response?.result?.token}`
          }
        })
        .catch((e) => {
          return e
        })
      return request
    }
  }
  return request
}
export function handleResponse(value) {
  return value
}
export async function handleApiError(error) {
  if (Axios.isCancel(error)) {
    throw error
  }
  if (!error.response) {
    throw error
  }
  if (error.response.status === 401 || error.response.status === 402) {
    return error
  } else if (error.response.status === 500) {
    throw error
  } else {
    // showToast(error.toString());
  }
  throw error
}
