import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen2'>
      <img src={toAbsoluteUrl('/media/logos/logo-2.png')} alt='Start logo' width={'100px'} />
      <span>Loading ...</span>
    </div>
  )
}
