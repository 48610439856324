/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/dashboard.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/general/user.svg'
        title='Users'
        fontIcon='bi bi-people'
      />
      <AsideMenuItemWithSub
        to='/image'
        icon='/media/icons/duotune/general/image.svg'
        title='Images'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/image/image' title='Background' hasBullet={true} />
        <AsideMenuItem to='/image/board' title='Board' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/animation'
        icon='/media/icons/duotune/general/animation.svg'
        title='Animation'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/medium'
        icon='/media/icons/duotune/general/medium.svg'
        title='Medium'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/standard'
        icon='/media/icons/duotune/general/standard.svg'
        title='Standard'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/subject'
        icon='/media/icons/duotune/general/subject.svg'
        title='Subject'
        fontIcon='bi-layers'
      />

      <AsideMenuItemWithSub
        to='/image'
        icon='/media/icons/duotune/general/activityno.svg'
        title='Activity No'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/main' title='Main Activity No' hasBullet={true} />
        <AsideMenuItem to='/no' title='Sub Activity No' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
        to='/no'
        icon='/media/icons/duotune/general/activityno.svg'
        title='Activity No'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItem
        to='/activity'
        icon='/media/icons/duotune/general/activity.svg'
        title='Activity'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/worksheet'
        icon='/media/icons/duotune/general/worksheet.svg'
        title='Worksheet'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/application'
        icon='/media/icons/duotune/general/app.svg'
        title='Application Version'
        fontIcon='bi-layers'
      />
    </>
  )
}
