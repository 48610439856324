import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../Api/Api'
import { api } from '../../Api/AuthApi'

export const getUserData = createAsyncThunk('getUserData', (data: any) => {
  // const { body, body2 } = data
  return ApiGet(api.getUser + data)
    .then((res) => {
      return res
    })
    .catch((err) => err)
})

export const postLogin = createAsyncThunk('postLogin', async (body: any) => {
  return ApiPost(api.login, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const postMedium = createAsyncThunk('postMedium', async (body: any) => {
  return ApiPost(api.addMedium, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const postStandard = createAsyncThunk('postStandard', async (body: any) => {
  return ApiPost(api.addStandard, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const forgetPassword = createAsyncThunk('forgetPassword', async (body: any) => {
  return ApiPost(api.forgetPassword, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const changePassword = createAsyncThunk('changePassword', async (body: any) => {
  return ApiPost(api.changePassword, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

//*****************UploadImage ***********************

export const uploadImage = createAsyncThunk('uploadImage', async (body: any) => {
  return ApiPost(api.uploadImage, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const uploadPdf = createAsyncThunk('uploadPdf', async (body: any) => {
  return ApiPost(api.uploadPdf, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

//************Add**************

export const addBackground = createAsyncThunk('addBackground', async (body: any) => {
  return ApiPost(api.addBackground, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const postSubject = createAsyncThunk('postSubject', async (body: any) => {
  return ApiPost(api.addSubject, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const postAnimation = createAsyncThunk('postAnimation', async (body: any) => {
  return ApiPost(api.addAnimation, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addBoard = createAsyncThunk('addBoard', async (body: any) => {
  return ApiPost(api.addBoard, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtyNo = createAsyncThunk('addActitivtyNo', async (body: any) => {
  return ApiPost(api.addActitivtyNo, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityOne = createAsyncThunk('addActivityOne', async (body: any) => {
  return ApiPost(api.addActivityOne, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTwo = createAsyncThunk('addActivityTwo', async (body: any) => {
  return ApiPost(api.addActivityTwo, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityThree = createAsyncThunk('addActivityThree', async (body: any) => {
  return ApiPost(api.addActivityThree, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityFour = createAsyncThunk('addActivityFour', async (body: any) => {
  return ApiPost(api.addActivityFour, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityFive = createAsyncThunk('addActivityFive', async (body: any) => {
  return ApiPost(api.addActivityFive, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivitySix = createAsyncThunk('addActivitySix', async (body: any) => {
  return ApiPost(api.addActivitySix, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtySeven = createAsyncThunk('addActitivtySeven', async (body: any) => {
  return ApiPost(api.addActitivtySeven, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtyEight = createAsyncThunk('addActitivtyEight', async (body: any) => {
  return ApiPost(api.addActitivtyEight, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityNine = createAsyncThunk('addActivityNine', async (body: any) => {
  return ApiPost(api.addActivityNine, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTen = createAsyncThunk('addActivityTen', async (body: any) => {
  return ApiPost(api.addActivityTen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityEleven = createAsyncThunk('addActivityEleven', async (body: any) => {
  return ApiPost(api.addActivityEleven, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTwelve = createAsyncThunk('addActivityTwelve', async (body: any) => {
  return ApiPost(api.addActivityTwelve, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivitySixteen = createAsyncThunk('addActivitySixteen', async (body: any) => {
  return ApiPost(api.addActivitySixteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtyEightteen = createAsyncThunk(
  'addActitivtyEightteen',
  async (body: any) => {
    return ApiPost(api.addActitivtyEightteen, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActivityNineteen = createAsyncThunk('addActivityNineteen', async (body: any) => {
  return ApiPost(api.addActivityNineteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityFifteen = createAsyncThunk('addActivityFifteen', async (body: any) => {
  return ApiPost(api.addActivityFifteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivitySeventeen = createAsyncThunk('addActivitySeventeen', async (body: any) => {
  return ApiPost(api.addActivitySeventeen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTwenty = createAsyncThunk('addActivityTwenty', async (body: any) => {
  return ApiPost(api.addActivityTwenty, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTwentyOne = createAsyncThunk('addActivityTwentyOne', async (body: any) => {
  return ApiPost(api.addActivityTwentyOne, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActivityTwentyFive = createAsyncThunk(
  'addActivityTwentyFive',
  async (body: any) => {
    return ApiPost(api.addActivityTwentyFive, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActitivtyTwentySix = createAsyncThunk(
  'addActitivtyTwentySeven',
  async (body: any) => {
    return ApiPost(api.addActivityTwentySix, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActitivtyTwentySeven = createAsyncThunk(
  'addActitivtyTwentySeven',
  async (body: any) => {
    return ApiPost(api.addActitivtyTwentySeven, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActitivtyTwentyEight = createAsyncThunk(
  'addActitivtyTwentyEight',
  async (body: any) => {
    return ApiPost(api.addActitivtyTwentyEight, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActivityTwentyThree = createAsyncThunk(
  'addActivityTwentyThree',
  async (body: any) => {
    return ApiPost(api.addActivityTwentyThree, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActitivtyThirteen = createAsyncThunk('addActitivtyThirteen', async (body: any) => {
  return ApiPost(api.addActitivtyThirteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtyFourteen = createAsyncThunk('addActitivtyFourteen', async (body: any) => {
  return ApiPost(api.addActitivtyFourteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addActitivtyTwentyTwo = createAsyncThunk(
  'addActitivtyTwentyTwo',
  async (body: any) => {
    return ApiPost(api.addActitivtyTwentyTwo, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addActitivtyTwentyFour = createAsyncThunk(
  'addActitivtyTwentyFour',
  async (body: any) => {
    return ApiPost(api.addActitivtyTwentyFour, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const addWorkSheet = createAsyncThunk('addWorkSheet', async (body: any) => {
  return ApiPost(api.addWorkSheet, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const addAplication = createAsyncThunk('addAplication', async (body: any) => {
  return ApiPost(api.addAplication, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

//******************Get****************
export const getMedium = createAsyncThunk('getMedium', async (body?: any) => {
  return ApiGet(api.getMedium + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getBackground = createAsyncThunk('getBackground', async (body?: any) => {
  return ApiGet(api.getBackground + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getBoard = createAsyncThunk('getBoard', async (body?: any) => {
  return ApiGet(api.getBoard + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getActivity = createAsyncThunk('getActivity', async (body?: any) => {
  return ApiGet(api.getActivity + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getActivityTwo = createAsyncThunk('getActivityTwo', async (body?: any) => {
  return ApiGet(api.getActivityTwo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getActivityNo = createAsyncThunk('getActivityNo', async (body?: any) => {
  return ApiGet(api.getActivityNo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const getAnimation = createAsyncThunk('getAnimation', async (body: any) => {
  return ApiGet(api.getAnimation + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getAnimationFilter = createAsyncThunk('getAnimationFilter', async (body?: any) => {
  return ApiGet(api.getAnimationFilter + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getStandard = createAsyncThunk('getStandard', async (body: any) => {
  return ApiGet(api.getStandard + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getSubject = createAsyncThunk('getSubject', async (body: any) => {
  return ApiGet(api.getSubject + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getWorkSheet = createAsyncThunk('getWorkSheet', async (body: any) => {
  return ApiGet(api.getWorkSheet + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getAplication = createAsyncThunk('getAplication', async (body: any) => {
  return ApiGet(api.getAplication + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
//******************Filter****************

export const ActivityNoFilter = createAsyncThunk('ActivityNoFilter', async (body: any) => {
  return ApiPost(api.ActivityNoFilter, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

//************************GetById **************
export const getByIdUser = createAsyncThunk('getByIdUser', async (body: any) => {
  return ApiGet(api.getByIdUser + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const byIdAnimation = createAsyncThunk('byIdAnimation', async (body: any) => {
  return ApiGet(api.byIdAnimation + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityOne = createAsyncThunk('getByIdActivityOne', async (body: any) => {
  return ApiGet(api.getByIdActivityOne + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityNo = createAsyncThunk('getByIdActivityNo', async (body: any) => {
  return ApiGet(api.getByIdActivityNo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityTwo = createAsyncThunk('getByIdActivityTwo', async (body: any) => {
  return ApiGet(api.getByIdActivityTwo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityThree = createAsyncThunk('getByIdActivityThree', async (body: any) => {
  return ApiGet(api.getByIdActivityThree + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityFour = createAsyncThunk('getByIdActivityFour', async (body: any) => {
  return ApiGet(api.getByIdActivityFour + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityFive = createAsyncThunk('getByIdActivityFive', async (body: any) => {
  return ApiGet(api.getByIdActivityFive + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivitySix = createAsyncThunk('getByIdActivitySix', async (body: any) => {
  return ApiGet(api.getByIdActivitySix + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivitySeven = createAsyncThunk('getByIdActivitySeven', async (body: any) => {
  return ApiGet(api.getByIdActivitySeven + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityEight = createAsyncThunk('getByIdActivityEight', async (body: any) => {
  return ApiGet(api.getByIdActivityEight + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityNine = createAsyncThunk('getByIdActivityNine', async (body: any) => {
  return ApiGet(api.getByIdActivityNine + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityTen = createAsyncThunk('getByIdActivityTen', async (body: any) => {
  return ApiGet(api.getByIdActivityTen + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdActivityEleven = createAsyncThunk(
  'getByIdActivityEleven',
  async (body: any) => {
    return ApiGet(api.getByIdActivityEleven + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwelve = createAsyncThunk(
  'getByIdActivityTwelve',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwelve + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityThirteen = createAsyncThunk(
  'getByIdActivityThirteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivityThirteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivitySixteen = createAsyncThunk(
  'getByIdActivitySixteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivitySixteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityEightteen = createAsyncThunk(
  'getByIdActivityEightteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivityEightteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)

export const getByIdActivityNineteen = createAsyncThunk(
  'getByIdActivityNineteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivityNineteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)

export const getByIdActivityFifteen = createAsyncThunk(
  'getByIdActivityFifteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivityFifteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivitySeventeen = createAsyncThunk(
  'getByIdActivitySeventeen',
  async (body: any) => {
    return ApiGet(api.getByIdActivitySeventeen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwenty = createAsyncThunk(
  'getByIdActivityTwenty',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwenty + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyOne = createAsyncThunk(
  'getByIdActivityTwentyOne',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyOne + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyThree = createAsyncThunk(
  'getByIdActivityTwentyThree',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyThree + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyFive = createAsyncThunk(
  'getByIdActivityTwentyFive',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyFive + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentySix = createAsyncThunk(
  'getByIdActivityTwentySeven',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentySix + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentySeven = createAsyncThunk(
  'getByIdActivityTwentySeven',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentySeven + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyEight = createAsyncThunk(
  'getByIdActivityTwentyEight',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyEight + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityFourteen = createAsyncThunk(
  'getByIdActivityFourteen',
  async (body: any) => {
    return ApiGet(api.getByIdActivityFourteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyTwo = createAsyncThunk(
  'getByIdActivityTwentyTwo',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyTwo + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdActivityTwentyFour = createAsyncThunk(
  'getByIdActivityTwentyFour',
  async (body: any) => {
    return ApiGet(api.getByIdActivityTwentyFour + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const getByIdWorkSheet = createAsyncThunk(
  'getByIdWorkSheet',
  async (body: any) => {
    return ApiGet(api.byIdWorkSheet + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
//**********************Delete************************* */
export const deleteUser = createAsyncThunk('deleteUser', async (body: any) => {
  return ApiDelete(api.deleteUser + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const deleteMedium = createAsyncThunk('deleteMedium', async (body: any) => {
  return ApiDelete(api.deleteMedium + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteStandard = createAsyncThunk('deleteStandard', async (body: any) => {
  return ApiDelete(api.deleteStandard + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteSubject = createAsyncThunk('deleteSubject', async (body: any) => {
  return ApiDelete(api.deleteSubject + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteAnimation = createAsyncThunk('deleteAnimation', async (body: any) => {
  return ApiDelete(api.deleteAnimation + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteWorkSheet = createAsyncThunk('deleteWorkSheet', async (body: any) => {
  return ApiDelete(api.deleteWorkSheet + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityOne = createAsyncThunk('deleteActivityOne', async (body: any) => {
  return ApiDelete(api.deleteActivityOne + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityTwo = createAsyncThunk('deleteActivityTwo', async (body: any) => {
  return ApiDelete(api.deleteActivityTwo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityThree = createAsyncThunk('deleteActivityThree', async (body: any) => {
  return ApiDelete(api.deleteActivityThree + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityFour = createAsyncThunk('deleteActivityFour', async (body: any) => {
  return ApiDelete(api.deleteActivityFour + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityFive = createAsyncThunk('deleteActivityFive', async (body: any) => {
  return ApiDelete(api.deleteActivityFive + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivitySix = createAsyncThunk('deleteActivitySix', async (body: any) => {
  return ApiDelete(api.deleteActivitySix + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivitySeven = createAsyncThunk('deleteActivitySeven', async (body: any) => {
  return ApiDelete(api.deleteActivitySeven + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityEight = createAsyncThunk('deleteActivityEight', async (body: any) => {
  return ApiDelete(api.deleteActivityEight + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityNine = createAsyncThunk('deleteActivityNine', async (body: any) => {
  return ApiDelete(api.deleteActivityNine + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityTen = createAsyncThunk('deleteActivityTen', async (body: any) => {
  return ApiDelete(api.deleteActivityTen + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityEleven = createAsyncThunk('deleteActivityEleven', async (body: any) => {
  return ApiDelete(api.deleteActivityEleven + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityTwelve = createAsyncThunk('deleteActivityTwelve', async (body: any) => {
  return ApiDelete(api.deleteActivityTwelve + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivitySixteen = createAsyncThunk(
  'deleteActivitySixteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivitySixteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityEightteen = createAsyncThunk(
  'deleteActivityEightteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivityEightteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)

export const deleteActivityNineteen = createAsyncThunk(
  'deleteActivityNineteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivityNineteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityFifteen = createAsyncThunk(
  'deleteActivityFifteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivityFifteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivitySeventeen = createAsyncThunk(
  'deleteActivitySeventeen',
  async (body: any) => {
    return ApiDelete(api.deleteActivitySeventeen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwenty = createAsyncThunk('deleteActivityTwenty', async (body: any) => {
  return ApiDelete(api.deleteActivityTwenty + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const deleteActivityTwentyOne = createAsyncThunk(
  'deleteActivityTwentyOne',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyOne + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentyFive = createAsyncThunk(
  'deleteActivityTwentyFive',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyFive + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentySix = createAsyncThunk(
  'deleteActivityTwentySeven',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentySix + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentySeven = createAsyncThunk(
  'deleteActivityTwentySeven',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentySeven + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentyEight = createAsyncThunk(
  'deleteActivityTwentyEight',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyEight + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)

export const deleteActivityTwentyThree = createAsyncThunk(
  'deleteActivityTwentyThree',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyThree + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityThirteen = createAsyncThunk(
  'deleteActivityThirteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivityThirteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentyTwo = createAsyncThunk(
  'deleteActivityTwentyTwo',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyTwo + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityFourteen = createAsyncThunk(
  'deleteActivityFourteen',
  async (body: any) => {
    return ApiDelete(api.deleteActivityFourteen + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityTwentyFour = createAsyncThunk(
  'deleteActivityTwentyFour',
  async (body: any) => {
    return ApiDelete(api.deleteActivityTwentyFour + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const deleteActivityNo = createAsyncThunk('deleteActivityNo', async (body: any) => {
  return ApiDelete(api.deleteActivityNo + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

//******************ActiveDeactive***********************

export const mediumActiveDeactive = createAsyncThunk('mediumActiveDeactive', async (body: any) => {
  return ApiPut(api.mediumActiveDeactive + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const standardActiveDeactive = createAsyncThunk(
  'standardActiveDeactive',
  async (body: any) => {
    return ApiPut(api.standardActiveDeactive + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const subjectActiveDeactive = createAsyncThunk(
  'subjectActiveDeactive',
  async (body: any) => {
    return ApiPut(api.subjectActiveDeactive + body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)

//******************Update****************
export const editUser = createAsyncThunk('editUser', async (body?: any) => {
  return ApiPut(api.editUser, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editBoard = createAsyncThunk('editBoard', async (body?: any) => {
  return ApiPut(api.editBoard, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editBackground = createAsyncThunk('editBackground', async (body?: any) => {
  return ApiPut(api.editBackground, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editMedium = createAsyncThunk('postMedium', async (body: any) => {
  return ApiPut(api.editMedium, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editStandard = createAsyncThunk('postStandard', async (body: any) => {
  return ApiPut(api.editStandard, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editSubject = createAsyncThunk('postSubject', async (body: any) => {
  return ApiPut(api.editSubject, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editAnimation = createAsyncThunk('postAnimation', async (body: any) => {
  return ApiPut(api.editAnimation, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityOne = createAsyncThunk('postActivityOne', async (body: any) => {
  return ApiPut(api.editActivityOne, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityTwo = createAsyncThunk('postActivityTwo', async (body: any) => {
  return ApiPut(api.editActivityTwo, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityThree = createAsyncThunk('postActivityThree', async (body: any) => {
  return ApiPut(api.editActivityThree, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityFour = createAsyncThunk('postActivityFour', async (body: any) => {
  return ApiPut(api.editActivityFour, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityFive = createAsyncThunk('postActivityFive', async (body: any) => {
  return ApiPut(api.editActivityFive, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivitySix = createAsyncThunk('postActivitySix', async (body: any) => {
  return ApiPut(api.editActivitySix, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivitySeven = createAsyncThunk('postActivitySeven', async (body: any) => {
  return ApiPut(api.editActivitySeven, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityEight = createAsyncThunk('postActivityEight', async (body: any) => {
  return ApiPut(api.editActivityEight, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityNine = createAsyncThunk('editActivityNine', async (body: any) => {
  return ApiPut(api.editActivityNine, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityTen = createAsyncThunk('postActivityTen', async (body: any) => {
  return ApiPut(api.editActivityTen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityEleven = createAsyncThunk('editActivityEleven', async (body: any) => {
  return ApiPut(api.editActivityEleven, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityTwelve = createAsyncThunk('editActivityTwelve', async (body: any) => {
  return ApiPut(api.editActivityTwelve, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityThirteen = createAsyncThunk('editActivityThirteen', async (body: any) => {
  return ApiPut(api.editActivityThirteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivitySixteen = createAsyncThunk('editActivitySixteen', async (body: any) => {
  return ApiPut(api.editActivitySixteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityEightteen = createAsyncThunk(
  'editActivityEightteen',
  async (body: any) => {
    return ApiPut(api.editActivityEightteen, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityNineteen = createAsyncThunk('editActivityNineteen', async (body: any) => {
  return ApiPut(api.editActivityNineteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityFifteen = createAsyncThunk('editActivityFifteen', async (body: any) => {
  return ApiPut(api.editActivityFifteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivitySeventeen = createAsyncThunk(
  'editActivitySeventeen',
  async (body: any) => {
    return ApiPut(api.editActivitySeventeen, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwenty = createAsyncThunk('editActivityTwenty', async (body: any) => {
  return ApiPut(api.editActivityTwenty, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityTwentyFive = createAsyncThunk(
  'editActivityTwentyFive',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyFive, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentySix = createAsyncThunk(
  'editActivityTwentySeven',
  async (body: any) => {
    return ApiPut(api.editActivityTwentySix, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentySeven = createAsyncThunk(
  'editActivityTwentySeven',
  async (body: any) => {
    return ApiPut(api.editActivityTwentySeven, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentyEight = createAsyncThunk(
  'editActivityTwentyEight',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyEight, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentyOne = createAsyncThunk(
  'editActivityTwentyOne',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyOne, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentyThree = createAsyncThunk(
  'editActivityTwentyThree',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyThree, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityFourteen = createAsyncThunk('editActivityFourteen', async (body: any) => {
  return ApiPut(api.editActivityFourteen, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editActivityTwentyTwo = createAsyncThunk(
  'editActivityTwentyTwo',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyTwo, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityTwentyFour = createAsyncThunk(
  'editActivityTwentyFour',
  async (body: any) => {
    return ApiPut(api.editActivityTwentyFour, body)
      .then((res) => {
        return res?.data
      })
      .catch((err) => err)
  }
)
export const editActivityNo = createAsyncThunk('postActivityNo', async (body: any) => {
  return ApiPut(api.editActivityNo, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editWorkSheet = createAsyncThunk('postWorkSheet', async (body: any) => {
  return ApiPut(api.editWorkSheet, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const editAplication = createAsyncThunk('editAplication', async (body: any) => {
  return ApiPut(api.editAplication, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
// admin api

export const getByIdProfile = createAsyncThunk('getByIdProfile', async (body: any) => {
  return ApiGet(api.getByIdProfile + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const editProfile = createAsyncThunk('editProfile', async (body: any) => {
  return ApiPut(api.editProfile, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const getMainActivity = createAsyncThunk('getMainActivity', async (body: any) => {
  return ApiGet(api.getMainActivity + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})

export const editMainActivity = createAsyncThunk('editMainActivity', async (body: any) => {
  return ApiPut(api.editMainActivity, body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})
export const getByIdMainActivity = createAsyncThunk('getByIdMainActivity', async (body: any) => {
  return ApiGet(api.getByIdMainActivity + body)
    .then((res) => {
      return res?.data
    })
    .catch((err) => err)
})