export enum responseEnum {
  SuccessCode = 1,
  ValidationCode = 404,
  ValidationCode2 = 400,
  ValidationCode3 = 409,
  InternalServerCode = 500,
  UnAuth = 401,
  TokenSuccess,
}
export const InvalidNumberKeys = [
  "KeyE",
  "Minus",
  "NumpadSubtract",
  "NumpadAdd",
  "NumpadDecimal",
  "Period"
];